<template>
  <admin-map />
</template>

<script>

import AdminMap from '@/components/AdminMap'

export default {
  name: 'AdminHome',
  components: {
    AdminMap
  }
}
</script>
