<template>
  <div class="box">
    <div class="map-container">
      <l-map
        ref="map"
        class="map"
        :zoom="zoom"
        :center="center"
        editable="true"
      >
        <l-tile-layer :url="url" />
        <l-geo-json
          v-for="(feature, idx) in filter"
          :key="'feature-'+idx"
          :geojson="feature"
          :options="options"
          @click="onEdit(feature)"
        />
      </l-map>
    </div>
    <div class="prices-container">
      <div class="filters-container">
        <v-card>
          <v-toolbar>
            <v-chip
              v-for="(city, index) in cities"
              :key="'chip-'+index"
              class="ma-2"
              filter
              :input-value="city === filterCity"
              @click="filterByCity(city)"
            >
              {{ city }}
            </v-chip>
            <v-spacer />
            <v-btn
              icon
              @click="drawer = !drawer"
            >
              <v-icon>
                {{ icons.mdiTune }}
              </v-icon>
            </v-btn>
          </v-toolbar>
        </v-card>
      </div>
      <div class="reference-container">
        <v-card
          v-for="price in filter"
          :key="price.id"
          :ref="'price-'+price.id"
          class="mb-3"
          color="white"
        >
          <v-toolbar
            dark
            color="blue lighten-1"
            style="cursor: pointer;"
            @click="onEdit(price)"
          >
            <v-toolbar-title>
              <div class="caption">
                {{ price.properties.city }}
              </div>
              <div class="h5">
                {{ price.properties.name }}
              </div>
            </v-toolbar-title>
            <v-spacer />
            <v-chip
              class="ma-2"
              color="primary"
            >
              {{ estimateResult(price, estimateTest).prixMoyen }}
            </v-chip>
          </v-toolbar>
          <v-container v-if="editFeature.id === price.id">
            <v-card
              color="blue lighten-5"
            >
              <admin-feature-form />
              <v-card-actions>
                <v-btn
                  v-if="price.properties.editGeo"
                  small
                  color="warning"
                  @click="onRemoveFeature(price.id)"
                >
                  Supprimer
                </v-btn>
                <v-spacer />
                <v-btn
                  small
                  @click="onCloseEdit"
                >
                  Annuler
                </v-btn>
                <v-btn
                  small
                  @click="onUpdateFeature"
                >
                  Mettre à jour
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-container>
        </v-card>
      </div>
    </div>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      temporary
      left
      width="50%"
    >
      <v-card>
        <v-card-title>
          Définir une estimation test
        </v-card-title>
        <v-card-text>
          <admin-estimate-form />
        </v-card-text>
      </v-card>
    </v-navigation-drawer>

    <v-dialog
      v-model="addDialog"
      max-width="600px"
      persistent
      transition="dialog-bottom-transition"
    >
      <v-card
        ref="addPrice"
        class="mb-3"
        style="max-width:600px;"
      >
        <v-toolbar
          dark
          color="blue lighten-1"
        >
          <v-btn
            icon
            dark
            @click="closeAdd"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Ajouter</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              dark
              text
              @click="onSaveFeature"
            >
              Sauver
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text>
          <admin-feature-form />
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
/* eslint-disable no-unused-vars */
import L from 'leaflet'
/* eslint-disable no-unused-vars */
import LDraw from 'leaflet-draw'
import 'leaflet-draw/dist/leaflet.draw.css'
import { LMap, LTileLayer, LGeoJson } from 'vue2-leaflet'
import 'leaflet/dist/leaflet.css'

import AdminEstimateForm from './AdminEstimateForm'
import AdminFeatureForm from './AdminFeatureForm'

import { mapActions, mapGetters, mapState } from 'vuex'
import {
  mdiPencil,
  mdiDelete,
  mdiCloseCircle,
  mdiTune
} from '@mdi/js'

export default {
  name: 'AdminMap',
  components: {
    LMap: LMap,
    LTileLayer: LTileLayer,
    LGeoJson: LGeoJson,
    AdminEstimateForm,
    AdminFeatureForm
  },
  data () {
    return {
      url: 'https://{s}.tile.osm.org/{z}/{x}/{y}.png',
      zoom: 10,
      center: [43.3, 5.4],
      bounds: null,
      addDialog: false,
      editedLayer: null,
      icons: {
        mdiPencil,
        mdiDelete,
        mdiCloseCircle,
        mdiTune
      },
      drawer: null,
      filterCity: ''
    }
  },
  computed: {
    ...mapState(['cities', 'editFeature', 'estimateTest']),
    ...mapGetters(['pricesParsed', 'estimateResult']),
    filter () {
      if (this.filterCity) {
        return this.pricesParsed.filter(x => x.properties.city === this.filterCity)
      } else {
        return this.pricesParsed
      }
    },
    options () {
      return {
        onEachFeature: this.onEachFeatureFunction()
      }
    }
  },
  mounted () {
    this.bindPrices()
    window.addEventListener('resize', () => {
      this.windowHeight = window.innerHeight
    })

    this.$nextTick(() => {
      const map = this.$refs.map.mapObject
      map.createPane('official')
      map.createPane('custom')

      // this.editableLayers = new window.L.FeatureGroup().addTo(map);

      const drawControl = new window.L.Control.Draw({
        position: 'topleft',
        draw: {
          polyline: false,
          polygon: true,
          rectangle: false,
          circle: false,
          marker: false,
          circlemarker: false
        }
      })

      map.addControl(drawControl)

      map.on(window.L.Draw.Event.CREATED, (e) => {
        this.setNewFeature()
        this.removeLayer()
        this.addDialog = true
        const layer = e.layer
        this.setEditFeature({ ...this.editFeature, geometry: layer.toGeoJSON().geometry })
      })
    })
  },
  methods: {
    ...mapActions([
      'bindPrices',
      'setEditFeature',
      'updateFeature',
      'removeFeature',
      'setStateEditFeature',
      'addPrice',
      'setNewFeature',
      'updatePolygon'
    ]),
    onSaveFeature () {
      this.addPrice()
      this.addDialog = false
      this.removeLayer()
    },
    onEditMap (feature) {
      this.scrollTo(feature)
      this.removeLayer()
    },
    scrollTo (feature) {
      const getRef = 'price-' + feature.id
      const ref = this.$refs[getRef][0]
      ref.$el.scrollIntoView()
    },
    removeLayer () {
      const map = this.$refs.map.mapObject
      if (this.editedLayer) {
        map.removeLayer(this.editedLayer)
        this.editedLayer = null
      }
    },
    onEdit (feature) {
      if (this.editFeature.id === feature.id) {
        this.onCloseEdit()
      } else {
        this.setEditFeature(feature)
        this.onEditMap(feature)
        const map = this.$refs.map.mapObject
        this.editedLayer = L.GeoJSON.geometryToLayer(feature)
        this.editedLayer.setStyle({
          color: 'red',
          fillOpacity: 0.2,
          weight: 3,
          fillColor: 'red'
        })
        this.editedLayer.addTo(map)
        this.bounds = this.editedLayer.getBounds()

        map.fitBounds(this.bounds)

        if (feature.properties.editGeo) {
          this.editedLayer.editing.enable()
        }
      }
    },
    onUpdateFeature () {
      const newPolygon = this.editedLayer.editing.latlngs[0][0].map(x => [x.lng, x.lat])
      if (this.editFeature.properties.editGeo) {
        this.updatePolygon(newPolygon)
      }
      this.updateFeature()
    },
    onRemoveFeature (id) {
      confirm('Méfi') && this.removeFeature(id)
      this.removeLayer()
    },
    closeAdd () {
      this.addDialog = false
      this.removeLayer()
    },
    onCloseEdit () {
      this.setNewFeature()
      this.removeLayer()
    },
    filterByCity (city) {
      if (this.filterCity === city) {
        this.filterCity = ''
      } else {
        this.filterCity = city
      }
    },
    onEachFeatureFunction () {
      return (feature, layer) => {
        if (!feature.properties.editGeo) {
          layer.setStyle(
            {
              pane: 'official',
              fillColor: 'grey',
              fillOpacity: 0.2,
              color: 'grey',
              weight: 1,
              opacity: 1
            }
          )
        } else {
          layer.setStyle(
            {
              pane: 'custom',
              fillColor: 'blue',
              fillOpacity: 0.1,
              weight: 1,
              opacity: 1
            }
          )
        }
        layer.bindTooltip(
          feature.properties.name,
          { permanent: false, sticky: true }
        )
      }
    }
  }
}
</script>

<style lang="scss">

  .box {
    display: grid;
    grid-template-columns: 1fr 1fr;
    height: calc(100vh - 64px);
    width: 100vw;

    @media screen and (min-width: 1280px) {
      grid-template-columns: 1fr 30%;
    }

  .map-container {
    position: sticky;
  }
  .prices-container {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    position: relative;
    padding: 1rem;
  }
  .filters-container {
    margin-bottom: 1rem;

  }
}
.map {
.leaflet-custom-pane {
  z-index: 401;
}
.leaflet-overlay-pane {
  z-index: 402;
}
}

</style>
