<template>
  <v-card-text>
    <v-row>
      <v-col cols="12">
        <v-subheader class="title">
          Type
        </v-subheader>
        <v-radio-group
          :value="estimateTest.type"
          @change="onEvent('type', $event)"
        >
          <v-radio
            v-for="(option, id) in options.type"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-subheader class="title">
          État
        </v-subheader>
        <v-radio-group
          :value="estimateTest.condition"
          @change="onEvent('condition', $event)"
        >
          <v-radio
            v-for="(option, id) in options.condition"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>

      <v-col cols="12">
        <v-subheader class="title">
          Année de construction
        </v-subheader>
        <v-radio-group
          :value="estimateTest.seniority"
          @change="onEvent('seniority', $event)"
        >
          <v-radio
            v-for="(option, id) in options.seniority"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="6">
        <v-subheader class="title mb-8">
          Superficie du bien
        </v-subheader>
        <v-text-field
          :value="estimateTest.surface"
          single-line
          type="number"
          suffix="㎡"
          @change="onEvent('surface', $event)"
        />
      </v-col>
      <v-col cols="12">
        <v-subheader class="title">
          Les atouts de votre bien
        </v-subheader>
        <v-switch
          :value="estimateTest.view"
          inset
          label="Vue exceptionnelle"
          @change="onEvent('view', $event)"
        />
        <v-switch
          :value="estimateTest.pool"
          inset
          label="Piscine"
          @change="onEvent('pool', $event)"
        />
      </v-col>

      <v-col cols="12">
        <v-subheader class="title">
          Extérieur
        </v-subheader>
        <v-radio-group
          :value="estimateTest.exterior"
          @change="onEvent('exterior', $event)"
        >
          <v-radio
            v-for="(option, id) in options.exterior"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-subheader class="title">
          Calme
        </v-subheader>
        <v-radio-group
          :value="estimateTest.quiet"
          @change="onEvent('quiet', $event)"
        >
          <v-radio
            v-for="(option, id) in options.quiet"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-subheader class="title">
          Ensoleillement
        </v-subheader>
        <v-radio-group
          :value="estimateTest.sunshine"
          @change="onEvent('sunshine', $event)"
        >
          <v-radio
            v-for="(option, id) in options.sunshine"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>
      <v-col cols="12">
        <v-subheader class="title">
          Vis-à-vis
        </v-subheader>
        <v-radio-group
          :value="estimateTest.opposite"
          @change="onEvent('opposite', $event)"
        >
          <v-radio
            v-for="(option, id) in options.opposite"
            :key="id"
            :value="option.value"
            :label="option.label"
          />
        </v-radio-group>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AdminEstimateForm',
  computed: {
    ...mapState(['estimateTest', 'options'])
  },
  methods: {
    ...mapActions(['setStateEstimate']),
    onEvent (key, value) {
      this.setStateEstimate({ key, value, type: 'test' })
    }
  }
}
</script>
