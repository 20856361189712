<template>
  <v-card-text>
    <v-text-field
      :value="editFeature.properties.name"
      label="Nom"
      @input="onEvent('name', $event)"
    />
    <v-select
      :value="editFeature.properties.city"
      :items="cities"
      label="Ville"
      @change="onEvent('city', $event)"
    />
    <v-text-field
      :value="editFeature.properties.price_u30"
      label="Prix -30 m2"
      number
      @input="onEvent('price_u30', $event)"
    />
    <v-text-field
      :value="editFeature.properties.price_u50"
      label="Prix -50 m2"
      number
      @input="onEvent('price_u50', $event)"
    />

    <v-text-field
      :value="editFeature.properties.price_u70"
      label="Prix -70 m2"
      number
      @input="onEvent('price_u70', $event)"
    />
    <v-text-field
      :value="editFeature.properties.price_u100"
      label="Prix -100 m2"
      number
      @input="onEvent('price_u100', $event)"
    />
    <v-text-field
      :value="editFeature.properties.price_u200"
      label="Prix -200 m2"
      number
      @input="onEvent('price_u200', $event)"
    />
    <v-text-field
      :value="editFeature.properties.price_p200"
      label="Prix +200 m2"
      number
      @input="onEvent('price_p200', $event)"
    />
    <v-text-field
      :value="editFeature.properties.maison"
      label="Maison"
      number
      @input="onEvent('maison', $event)"
    />
    <v-text-field
      :value="editFeature.properties.vue"
      label="Vue exceptionnelle"
      number
      @input="onEvent('vue', $event)"
    />
    <v-text-field
      :value="editFeature.properties.sansExterieur"
      label="Sans extérieur"
      number
      @input="onEvent('sansExterieur', $event)"
    />
  </v-card-text>
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'AdminFeatureForm',
  computed: {
    ...mapState(['editFeature', 'cities'])
  },
  methods: {
    ...mapActions(['setStateEditFeature']),
    onEvent (key, value) {
      this.setStateEditFeature({ key, value })
    }
  }

}
</script>
